@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  margin: 0;
  scroll-behavior: smooth;
  /* font-family: "NeoSansW23"; */
}

#__next {
  overflow: hidden;
}

#__next {
  overflow: hidden;
}

@font-face {
  font-family: "NeoSansW23";
  src: url("/fonts/alfont_com_NeoSansW23-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "NeoSansW23";
  src: url("/fonts/alfont_com_AlFont_com_Neo-Sans-W23-m-1.ttf") format("truetype");
  font-weight: 590;
  font-display: swap;
}

@font-face {
  font-family: "SF-Pro";
  src: url("/fonts/Mulish-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "SF-Pro";
  src: url("/fonts/Mulish-Bold.ttf") format("truetype");
  font-weight: 590;
  font-display: swap;
}

.responsive {
  padding: 0 9%;
}

.font-ar {
  font-family: "NeoSansW23";
}

.font-en {
  font-family: "SF-Pro";
}

.responsive-navbar {
  padding: 0 45px;
}

.max-width {
  @apply max-w-[calc(100vw-64px)] xl:max-w-[78.07rem] 2xl:max-w-[90rem] mx-auto w-full;
}

@media screen and (min-width: 1440px) {
  .responsive {
    padding: 0 135px;
  }

  .testmonials {
    width: 573px;
  }

  .project {
    width: 575px;
  }

  .container {
    width: 575px;
  }

  /* #ourBlogs .swiper-pagination .swiper-pagination-bullet {
    display: none;
  } */
}

@media screen and (min-width: 1710px) {
  .responsive {
    padding: 0;
  }

  .testmonials {
    width: 49%;
  }

  .project {
    width: 700px;
  }

  .visionSlider {
    width: 90%;
  }

  .button {
    width: 700px;
  }
}

.t {
  position: relative;
  width: 595px;
  height: 591px;
  background: #0af0dd;
  opacity: 0.33;
  border-radius: 100%;
  filter: blur(60px);
}

.WMAD {
  background: linear-gradient(135.41deg,
      rgba(255, 255, 255, 0.15) 1.97%,
      rgba(255, 255, 255, 0.07) 37.35%,
      rgba(255, 255, 255, 0) 49.99%,
      rgba(255, 255, 255, 0.02) 64.14%,
      rgba(255, 255, 255, 0.2) 99.02%);
}

.bottomborder,
.test {
  background: linear-gradient(70.08deg,
      #2ebbff 12.42%,
      #fe17ff 53.94%,
      #6232ea 93.64%);
  border-image-slice: 1;
  border-bottom: 5px solid transparent;
  height: 5px;
  border: none;
  border-radius: 5px;
  width: 138px;
}

.arabicbottomborder {
  background: linear-gradient(70.08deg,
      #2ebbff 12.42%,
      #fe17ff 53.94%,
      #6232ea 93.64%);
  border-image-slice: 1;
  border-bottom: 5px solid transparent;
  height: 5px;
  border: none;
  border-radius: 5px;
  width: 90px;
}

.Testimonials {
  background: rgba(1, 1, 64, 0.15);
  border-image-slice: 1;
  border-bottom: 5px solid transparent;
}

@media screen and (max-width: 600px) {

  .bottomborder,
  .test {
    height: 3px;
  }
}

@media screen and (max-width: 640px) {
  #ourBlogs .swiper-pagination .swiper-pagination-bullet {
    display: block;
  }
}

@media screen and (min-width: 975px) {
  #ourBlogs .swiper-pagination .swiper-pagination-bullet {
    display: none;
  }
}

.hideScrollBar::-webkit-scrollbar {
  display: none;
}

.Dropdown-menu {
  @apply bg-white ml-[-7px] max-h-[11rem] overflow-x-hidden overflow-y-auto w-[calc(100%-7.48px)] z-[100] mb-4;
}

.Dropdown-option {
  @apply h-12 flex px-2 mr-[5px] cursor-pointer items-center;
}

.Dropdown-arrow-wrapper {
  @apply w-full absolute top-0 right-0 flex justify-end;
}

.Dropdown-control {
  margin-left: -8px;
  height: 100%;
  background: white;
  padding-left: 8px;
  border-radius: 8px;
}

.Dropdown-option:last-child {
  border-radius: 0 0 8px 8px;
}

.Dropdown-option:hover {
  background-color: #01014020;
}

.Dropdown-placeholder {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 8px;
  margin-left: -8px;
  height: 48px;
}

/* width */
.Dropdown-menu::-webkit-scrollbar {
  width: 12px;
}

/* Track */
.Dropdown-menu::-webkit-scrollbar-track {
  background: #c5c6c7;
  border-left: 4px solid white;
  border-right: 4px solid white;
}

/* Handle */
.Dropdown-menu::-webkit-scrollbar-thumb {
  /* background: #c5c6c7; */
  background: #010140;
  border-radius: 6px;
  height: 47px;
}

.ourteamcard {
  background: linear-gradient(203.66deg,
      rgba(255, 255, 255, 0.111) 0.68%,
      rgba(255, 255, 255, 0.0473684) 21.41%,
      rgba(255, 255, 255, 0) 42.13%,
      rgba(255, 255, 255, 0.078) 62.85%,
      rgba(255, 255, 255, 0.0324292) 83.57%);
}

.background {
  height: 100%;
  width: 100%;
  background-image: radial-gradient(circle at 10% 30%,
      #ff295f42,
      transparent 25%),
    radial-gradient(circle at 95% 30%, #b34ffb3c, transparent 25%),
    radial-gradient(circle at 30% 90%, #fb44e32f, transparent 25%),
    radial-gradient(circle at 60% 10%, #4c2ec540, transparent 25%);
}

@media screen and (max-width: 1250px) {
  .custom-breakpoint-1250 div {
    grid-column: span 12 / span 12;
  }

  .hide-at-1200 {
    display: none;
  }

  /* #ourBlogs .swiper-pagination .swiper-pagination-bullet {
    display: none;
  } */
}

/* Navbar */
.dropdown:hover .dropdown-content,
.dropdown:active .dropdown-content {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
}

/* arrow down  */
.dropdown:hover .dropdownArrow {
  /* Rotate the arrow 180 degrees on hover */
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out;
  /* justify-content: start !important; */
}

.dropdown:hover .dropdownArrow {
  transform: rotate(180deg);
}

.dropdownArrow {
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out;
}

.downArrow {
  transition: transform 0.2s ease-in-out;
  transform: rotate(180deg);
}

.upArrow {
  transition: transform 0.2s ease-in-out;
  transform: rotate(0deg);
}

/* Hide the input by default */

/* Show the input on hover */

.navSearchInput {
  width: 24px;
  transition: width 0.4s;
}

.navSearchInput:hover {
  width: 210px;
}

.dropdown.overflow-hidden:hover .about_underline {
  display: none;
}

/* navbar end  */

/* sidebar start  */

.sidebar-container-close {
  @apply w-0 overflow-hidden transition-all duration-300 ease-in-out;
}

.sidebar-container-open {
  @apply w-[325px] sm:w-[350px] transition-all duration-300 ease-in-out;
}

.sidebar-container-open::-webkit-scrollbar {
  display: none;
}

.sidebar-about-open {
  height: 35px;
  overflow-y: clip;
  transition: height 0.4s ease-in-out;
}

.sidebar-about-close {
  height: fit-content;
  transition: height 0.4s ease-in-out;
  overflow-y: clip;
}

/* rounded gradient borders  */
.custom-rounded-border {
  border: double 2px transparent;
  border-radius: 36px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #2ebbff, #fe17ff, #6232ea);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.custom-rounded-border.navbar {
  width: 160px;
  height: 48px;
  background-image: linear-gradient(#d8eef9, #d8eef9),
    radial-gradient(circle at top left, #2ebbff, #fe17ff, #6232ea);
}

.custom-rounded-border.justify-self-end {
  border: double 3px transparent;
}

.custom-rounded-border-coookies {
  width: 150px;
  height: 64px;
  border: double 2px transparent;
  border-radius: 36px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #2ebbff, #fe17ff, #6232ea);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

/* sidebar end  */

:root {
  --primary: #010140;
  --secondary: #c5c6c7;
}

.scrollbarr {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
  margin-right: 4px;
  margin-left: 4px;
}

.scrollbarr::-webkit-scrollbar {
  width: 15px;
}

.scrollbarr::-webkit-scrollbar-track {
  background: var(--secondary);
  border-radius: 5px;
}

.scrollbarr::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 14px;
  border: 3px solid var(--secondary);
}

.testmonials_bg {
  background: linear-gradient(135.41deg,
      rgba(255, 255, 255, 0.15) 1.97%,
      rgba(255, 255, 255, 0.07) 37.35%,
      rgba(255, 255, 255, 0) 49.99%,
      rgba(255, 255, 255, 0.02) 64.14%,
      rgba(255, 255, 255, 0.2) 99.02%),
    linear-gradient(0deg, rgba(246, 246, 246, 0.5), rgba(246, 246, 246, 0.5));

  border: 2px solid;

  border-image-source: linear-gradient(135.41deg,
      rgba(255, 255, 255, 0.15) 1.97%,
      rgba(255, 255, 255, 0.07) 37.35%,
      rgba(255, 255, 255, 0) 49.99%,
      rgba(255, 255, 255, 0.02) 64.14%,
      rgba(255, 255, 255, 0.2) 99.02%);
}

.product_bg {
  background: linear-gradient(135.41deg,
      rgba(255, 255, 255, 0.15) 1.97%,
      rgba(255, 255, 255, 0.07) 37.35%,
      rgba(255, 255, 255, 0) 49.99%,
      rgba(255, 255, 255, 0.02) 64.14%,
      rgba(255, 255, 255, 0.2) 99.02%);
}

.swiper-button-prev:after {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.Logo {
  background: linear-gradient(210.84deg,
      rgba(54, 169, 225, 0.2356) 11.1%,
      rgba(104, 201, 249, 0) 52.47%,
      rgba(54, 169, 225, 0.1984) 92.3%);
  /* border-image-source: linear-gradient(
    1deg,
    rgba(255, 255, 255, 0.8) 37.35%,
    rgba(255, 255, 255, 0.69) 49.99%,
    rgba(255, 255, 255, 0.02) 64.14%,
    rgba(255, 255, 255, 0.714) 99.02%
  ); */

  border-width: 7px;
}

#circle {
  max-width: 30vw;
  min-width: 4vw;
  max-height: 100vw;
  min-height: 2vw;
  width: 100%;
  height: 100%;
  border-radius: 80px;
  background: blue;
}

#products .swiper-slide {
  margin-top: 32px !important;
  height: 178px !important;
  width: 372px !important;
}

#products .swiper-slide-active {
  height: 237px !important;
  width: 372px !important;

  /* margin-top: 0 !important; */
}

#products.swiper-pagination {
  margin: auto !important;
  left: 50%;
  transform: translateX(-50%);
  background: red !important;
  display: flex !important;
  flex-direction: row;
}

#products .swiper-pagination-clickable {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

#products .swiper-pagination-bullet-active {
  width: 10px !important;
  /* margin-top: 80px !important; */
  align-items: center;
  justify-content: center;
  background: red !important;
  height: 10px !important;
  padding: 5px !important;
  /* content: url(../public/products/pagination.svg); */
  /* background: #be72f3 !important; */
  box-shadow: 0 0 0 1px #010140, 0 0 0 2px rgba(190, 114, 243, 0.5),
    0 0 0 5px #010140, 0 0 0 7px #6902b355 !important;
  border: 2px solid #010140 !important;
  background: #951b81 !important;
}

#products .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  /* margin: -100px 6px !important; */
  background: rgba(255, 255, 255, 0.8);
}

.swiper-slide .btn {
  display: none;
}

/* Check this with the team  */
#products .swiper-slide.swiper-slide-active {
  height: 600px;
}

.swiper-slide.swiper-slide-active .btn {
  display: block;
  display: flex;
  align-items: center;
  text-align: center;
}

.product_btn {
  width: 160px;
  height: 48px;
  border: double 2px transparent;
  border-radius: 36px;
  background-image: linear-gradient(#0d1b42, #0d1b42),
    radial-gradient(circle at top left, #2ebbff, #fe17ff, #6232ea);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  font-weight: 510;
  font-size: 16px;
  line-height: 19px;
  color: #f6f6f6;
}

/* Hompe page style  */

/* slider */
.homePageCarousel {
  display: flex !important;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 90rem !important;
}

@media screen and (max-width: 767px) {
  .homePageCarousel {
    display: flex !important;
    align-items: center !important;
  }
}

.slick-dots {
  position: relative !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-top: -73px !important;
  margin-bottom: 10px !important;
}

.slick-dots li {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 22px !important;
  height: 22px !important;
  margin: 0 !important;
}

@media screen and (min-width: 767px) {
  .slick-dots {
    margin-top: -61px !important;
    margin-bottom: 20px !important;
  }
}

.box {
  width: 200px;
  height: 200px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* components/assets/Date.svg */
input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url('../components/assets/Date.svg') no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
  cursor: pointer;
}

.scrollContainer {
  @apply flex h-[40vw] justify-center items-center overflow-hidden mt-[-20px];
}

image {
  @apply transition-all duration-[1500] absolute;
}

body {
  @apply overflow-x-hidden scroll-smooth;
}

.scrollAnimationSection {
  @apply w-full px-[9%] customBackground relative overflow-hidden mt-[-20px] flex items-start flex-col-reverse md:flex-row;
}

.phoneDiv {
  @apply z-10 origin-bottom-right w-[600px] xl:w-auto transition-all duration-500;
}

.bigWhiteCircle {
  @apply bg-[#ffffff15] rounded-full;
}

.placeTitle,
.placeText,
.placeButton {
  @apply absolute font-[600] text-[32px] xl:text-[48px] text-white leading-[40px] xl:leading-[56px] transition-all duration-[1s];
}

.placeText {
  @apply font-[400] text-[16px] xl:text-[18px] leading-[26px];
}

.placeButton {
  @apply font-[510] text-[16px] leading-[40px] bottom-[5vw] xl:bottom-[9vw] flex items-center;
}

.placeTitle-small {
  @apply font-[590] text-[22.5px] sm:text-[24px] text-white leading-[32px] mt-12;
}

.placeText-small {
  @apply text-[16px] text-white leading-[20px] mt-6;
}

.circle1,
.circle2,
.circle3 {
  @apply bg-[#ffffff15] z-[5] absolute rounded-full transition-all duration-500;
}

.circle1 {
  @apply mr-[230px] xl:mr-[290px] ml-[15px] xl:ml-[10px] w-[350px] xl:w-[490px] h-full;
}

.circle2 {
  @apply mx-[-30px] xl:mx-[-50px] w-[200px] xl:w-[350px] h-full;
}

.circle3 {
  @apply bottom-[-50px] right-[565px] xl:right-[725px] w-[130px] h-[130px] xl:w-[200px] xl:h-[200px];
}

.circle1-small {
  @apply bottom-[29px] w-full md:w-auto justify-center right-0 md:right-[82px] md:left-[50px] absolute flex md:justify-end;
}

.circle2-small {
  @apply bottom-[167px] right-[-24px] left-[-56px] absolute flex justify-end;
}

.circle2-small div {
  @apply w-[121px] h-[121px] bg-[#ffffff15] rounded-full;
}

.circle1-small div {
  @apply min-w-[266px] h-[266px] bg-[#ffffff15] rounded-full;
}

.stars {
  @apply z-0 h-[590px] mr-[50px] xl:mr-[155px] ml-[130px] xl:ml-[200px] w-[590px] xl:w-[650px];
}

.stars-small,
.smallPhone,
.smallHand {
  @apply z-10 w-full mx-auto inset-x-0 absolute bottom-[-40px] flex justify-center md:justify-end items-end [&>*]:min-w-fit xsm:[&>*]:ml-[-4rem] md:[&>*]:ml-0;
}

.smallPhone {
  @apply bottom-[-2px] xsm:[&>*]:ml-[-14rem] md:[&>*]:ml-0;
}

.smallHand {
  @apply xsm:[&>*]:mr-[-14rem] md:[&>*]:mr-0;
}

.customBackground {
  background: radial-gradient(241.19% 244.49% at 123.84% 111.37%,
      #2ebbff 0%,
      #fe17ff 68.41%,
      #6232ea 100%);
}

/* Home page projects carusel slides spacing  */
.slick-list {
  margin: 0 -9%;
}

.slick-slide>div {
  padding: 0 9%;
}

.glassProduct {
  background: linear-gradient(135.41deg,
      rgba(255, 255, 255, 0.15) 1.97%,
      rgba(255, 255, 255, 0.07) 37.35%,
      rgba(255, 255, 255, 0) 49.99%,
      rgba(255, 255, 255, 0.02) 64.14%,
      rgba(255, 255, 255, 0.2) 99.02%);
}

.toast-message-success {
  background: #e9f5e4 !important;
  color: #6abf4b !important;
}

.toast-message-error {
  background: #fadfdd !important;
  color: #e1251b !important;
}

.toast-message-warning {
  background: #fff4dd !important;
  color: #ffb71b !important;
}

.swiper-pagination {
  top: 85%;
}

.button {
  position: relative;
  border-radius: 15px;
  z-index: 1;
}

.button:after {
  border-radius: 15px;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease-out;
  z-index: -2;
  opacity: 0;
}

.button:hover:after {
  opacity: 1;
}

.motion-div {
  will-change: transform;
}

.example-div {
  width: 400px;
  height: 500px;
  position: relative;
}

.example-div .example-element {
  position: absolute;
  top: 0;
  left: 0;
  animation-name: example-animation;
  animation-duration: 6s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-play-state: paused;
}

/* 
#ourBlogs.swiper-pagination {
  margin: auto !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background: red !important;
  display: flex !important;
  flex-direction: row !important;
}
#ourBlogs .swiper-pagination-clickable {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 50px !important;
}

#ourBlogs .swiper-pagination-bullet-active {
  width: 10px !important;
  align-items: center !important;
  justify-content: center !important;
  background: red !important;
  height: 10px !important;
  padding: 5px !important;
  box-shadow: 0 0 0 1px #010140, 0 0 0 2px rgba(190, 114, 243, 0.5),
    0 0 0 5px #010140, 0 0 0 7px #6902b355 !important;

  background: #951b81 !important;
}
#ourBlogs .swiper-pagination .swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  background: rgba(255, 255, 255, 0.8) !important;
} */
#ourBlogs .swiper-pagination .swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;

  /* background: rgba(255, 255, 255, 0.8) !important; */
}

#ourBlogs .swiper-pagination-bullet-active {
  width: 10px !important;
  align-items: center !important;
  justify-content: center !important;
  background: red !important;
  /* height: 10px !important; */
  /* padding: 5px !important; */
  /* box-shadow: 0 0 0 1px #010140, 0 0 0 2px rgba(190, 114, 243, 0.5), */
  /* 0 0 0 5px #010140, 0 0 0 7px #6902b355 !important; */
  background: #951b81 !important;
}

#ourBlogs .swiper-pagination-clickable {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 50px !important;
  /* background-color: red; */
  margin-top: 1% !important;
}

#ourBlogs.swiper-pagination {
  margin: auto !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background: red !important;
  display: flex !important;
  flex-direction: row !important;
}

.video-modal-container .video-container {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio for responsive video */
  height: 0;
  overflow: hidden;
}

.video-modal-container .video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}